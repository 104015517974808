import { Controller } from 'stimulus';
import config from 'config';

const maxFileSize = 300;

export default class extends Controller {
  static targets = ['input'];
  static values = { prefix: String, key: String };

  connect() {
    this.inputTarget.click();
  }

  submit({ target }) {
    if (target.files[0].size / 1024 > maxFileSize) {
      alert(
        `文件大小不能超过 ${maxFileSize}KB，当前文件大小为${
          target.files[0].size / 1024
        }KB`,
      );
      return false;
    }

    this.element.submit();
    this.element.closest('.modal').modal.hide();

    const input = document.querySelector(`input#${this.keyValue}`);
    if (input) {
      input.value = `${config.ossHost}${this.prefixValue}${target.files[0].name}`;
      input.dispatchEvent(new Event('change'));
    }
  }
}
