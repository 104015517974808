import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['textarea'];

  connect() {
    this.textareaTarget.addEventListener('input', () => {
      this.element.dataset.replicatedValue = this.textareaTarget.value;
    });

    if (this.textareaTarget.value) {
      this.format();
    }
  }

  format() {
    const { value } = this.textareaTarget;
    try {
      const pretty = JSON.stringify(JSON.parse(value), undefined, 4);
      this.textareaTarget.value = pretty;
    } catch (error) {
      alert(`扩展配置错误：${error}`);
    }
    this.textareaTarget.dispatchEvent(new Event('input'));
  }
}
