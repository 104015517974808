import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { ids: Array };

  connect() {
    this.element[this.identifier] = this;
  }

  submit() {
    const formData = new FormData(this.element);
    Rails.ajax({
      url: `${this.element.action}?skip_toast=true`,
      type: 'POST',
      data: formData,
    });
  }

  sync() {
    const formData = new FormData(this.element);
    Rails.ajax({
      url: this.element.action,
      type: 'POST',
      data: formData,
    });

    formData.delete('project_config[ad_id]');

    this.idsValue.forEach((id) => {
      const url = this.element.action.replaceAll(/\d+$/g, id);
      Rails.ajax({
        url: `${url}?skip_toast=true`,
        type: 'POST',
        data: formData,
      });
    });
  }
}
