import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'project'];

  connect() {
    const key = this.inputTarget.value || '';

    [...this.projectTargets].forEach((element) => {
      if (!element.innerText.includes(key)) {
        element.closest('tr').remove();
      }
    });
  }
}
