import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

const storageKey = 'project-tag-selector';

export default class extends Controller {
  static targets = ['tags'];

  connect() {
    let tagIds = localStorage.getItem(storageKey);
    if (tagIds) {
      this.tagIds = tagIds.split(',');
      this.renderTags();

      if (!location.search.includes('q%5B')) {
        this.submit();
      }
    }
  }

  renderTags() {
    [...this.tagsTarget.querySelectorAll('.badge')].forEach((element) => {
      element.classList.add('bg-light', 'text-dark');
      element.querySelector('input').checked = false;
    });

    this.tagIds.forEach((tagId) => {
      [...this.tagsTarget.querySelectorAll(`[data-id="${tagId}"]`)].forEach(
        (element) => {
          element.classList.remove('bg-light', 'text-dark');
          element.querySelector('input').checked = true;
        },
      );
    });
  }

  submit() {
    const form = this.element.closest('form');
    if (form) setTimeout(() => Rails.fire(form, 'submit'), 50);
  }

  change(event) {
    event.preventDefault();
    event.target.classList.toggle('bg-light');

    this.tagIds = [
      ...this.tagsTarget.querySelectorAll(
        '.badge:not(.bg-light):not([data-id="blank"])',
      ),
    ].map(({ dataset }) => dataset.id);

    localStorage.setItem(storageKey, this.tagIds);
    this.renderTags();
    this.submit();
  }

  blank(event) {
    event.preventDefault();

    if (event.target.classList.contains('bg-light')) {
      this.tagIds = ['blank'];
    } else {
      this.tagIds = [
        ...this.tagsTarget.querySelectorAll('.badge:not([data-id="blank"])'),
      ].map(({ dataset }) => dataset.id);
    }

    localStorage.setItem(storageKey, this.tagIds);
    this.renderTags();
    this.submit();
  }
}
