import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { url: String };

  connect() {
    Rails.ajax({ url: this.urlValue, type: 'GET', dataType: 'script' });
  }
}
