import { Controller } from 'stimulus';
import { Toast } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
    this.toast = new Toast(this.element);

    this.element.addEventListener('hidden.bs.toast', () => {
      this.element.remove();
    });

    this.toast.show();
  }

  hide() {
    this.toast.hide();
  }
}
