import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  submit(event) {
    event.preventDefault();
    const formData = new FormData(this.element);
    const asString = new URLSearchParams(formData).toString();
    Turbolinks.clearCache();
    Turbolinks.visit(`${window.location.pathname}?${asString}`, {
      action: 'replace',
    });
    return false;
  }
}
