import { Controller } from 'stimulus';

const storageKey = 'project-tag-selector-with-selector';

export default class extends Controller {
  static targets = ['tags', 'list'];

  initialize() {
    let tagIds = localStorage.getItem(storageKey);
    if (tagIds) {
      this.tagIds = tagIds.split(',');
      this.renderTags();
      this.renderProjects();
    }
  }

  renderTags() {
    [...this.tagsTarget.querySelectorAll('.badge')].forEach((element) => {
      element.classList.add('bg-light', 'text-dark');
    });

    this.tagIds.forEach((tagId) => {
      [...this.tagsTarget.querySelectorAll(`[data-id="${tagId}"]`)].forEach(
        (element) => {
          element.classList.remove('bg-light', 'text-dark');
        },
      );
    });
  }

  renderProjects() {
    [...this.listTarget.querySelectorAll('[data-tag-ids]')].forEach(
      (element) => {
        element.classList.add('d-none');
      },
    );
    this.tagIds.forEach((tagId) => {
      [
        ...this.listTarget.querySelectorAll(`[data-tag-ids*=",${tagId},"]`),
      ].forEach((element) => {
        element.classList.remove('d-none');
      });
    });
  }

  change(event) {
    const { target } = event;
    event.preventDefault();

    target.classList.toggle('bg-light');
    target.classList.toggle('text-dark');

    this.tagIds = [
      ...this.tagsTarget.querySelectorAll('.badge:not(.bg-light)'),
    ].map(({ dataset }) => dataset.id);

    localStorage.setItem(storageKey, this.tagIds);
    this.renderProjects();
  }
}
