import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { data: Array };

  connect() {
    this.chart = echarts.init(this.element, null, {
      renderer: 'svg',
    });
    // Turbolinks 缓存时不渲染
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return;
    }
    this.chart.setOption({
      xAxis: {
        type: 'time',
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      color: ['#2da562'],
      grid: {
        top: '20px',
        left: 0,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          data: this.dataValue,
          type: 'line',
          smooth: true,
          silent: true,
          showSymbol: false,
          markPoint: {
            data: [{ type: 'max' }],
            symbolSize: 0,
            label: {
              offset: [0, -5],
              color: 'rgba(0, 0, 0, 1)',
              fontSize: 10,
            },
          },
          areaStyle: {
            opacity: 0.1,
          },
          lineStyle: {
            opacity: 0.5,
            width: 1,
          },
        },
      ],
    });
  }

  disconnect() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  resize() {
    if (this.chart) {
      this.chart.resize();
    }
  }
}
