import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'button'];

  copy() {
    this.sourceTarget.select();
    document.execCommand('copy');

    const text = this.buttonTarget.innerText;
    this.buttonTarget.innerText = '已复制';
    setTimeout(() => {
      this.buttonTarget.innerText = text;
    }, 2000);
  }
}
