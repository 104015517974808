import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
    this.modal = new Modal(this.element);

    this.element.addEventListener('shown.bs.modal', () => {
      const input = this.element.querySelector('input[type="text"][autofocus]');
      if (input) input.focus();
    });

    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove();
    });

    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
