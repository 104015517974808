import { Controller } from 'stimulus';

function parseResult(result) {
  const resultArray = [];
  result.split('\n').forEach(function (row) {
    const rowArray = [];
    row.split(',').forEach(function (cell) {
      rowArray.push(cell);
    });
    resultArray.push(rowArray);
  });
  return resultArray;
}

function createTable(array, target) {
  let content = '<thead><tr>';
  array[0].forEach((cell) => (content += `<th>${cell}</th>`));
  content += '</tr></thead>';

  array.slice(1).forEach((row) => {
    content += '<tr>';
    row.forEach((cell) => (content += `<td>${cell}</td>`));
    content += '</tr>';
  });
  target.innerHTML = content;
}

export default class extends Controller {
  static targets = ['input', 'table'];

  connect() {
    this.inputTarget.addEventListener('change', () => {
      const reader = new FileReader();
      const f = this.inputTarget.files[0];

      reader.onload = ({ target }) => {
        const csvArray = parseResult(target.result);
        createTable(csvArray, this.tableTarget);
      };
      reader.readAsText(f);
    });
  }
}
