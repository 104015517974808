import { Controller } from 'stimulus';
import DragSelect from 'dragselect';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.element[this.identifier] = this;

    this.dragselect = new DragSelect({
      selectables: this.element.querySelectorAll('.selector-item[data-value]'),
      area: this.element,
      multiSelectMode: true,
      dragKeys: {
        up: ['useless'],
        down: ['useless'],
        left: ['useless'],
        right: ['useless'],
      },
      callback: (items) => {
        this.inputTarget.value = items.map((i) => i.dataset.value).join(',');
      },
    });

    if (this.inputTarget.value) {
      this.inputTarget.value.split(',').forEach((value) => {
        const element = this.element.querySelector(
          `.selector-item[data-value="${value}"]`,
        );
        if (element) this.dragselect.addSelection(element);
      });
    }
  }

  selectAll() {
    const elements = this.element.querySelectorAll(
      '.selector-item[data-value]',
    );
    this.dragselect.addSelection(elements);
    this.inputTarget.value = [...elements]
      .map((element) => element.dataset.value)
      .join(',');
  }

  unSelectAll() {
    this.dragselect.clearSelection();
    this.inputTarget.value = '';
  }

  selectNormal() {
    this.dragselect.addSelection(
      this.element.querySelectorAll('.selector-item[data-value]'),
    );

    const wdays = [1, 2, 3, 4, 5];
    const hours = [9, 10, 11, 14, 15, 16, 17, 18];
    wdays.forEach((wday) => {
      hours.forEach((hour) => {
        const elements = this.element.querySelectorAll(
          `.selector-item[data-value^="${wday}-${hour}"]`,
        );
        this.dragselect.removeSelection(elements);
      });
    });

    const selected = this.element.querySelectorAll(
      '.selector-item.ds-selected',
    );
    this.inputTarget.value = [...selected]
      .map((element) => element.dataset.value)
      .join(',');
  }
}
