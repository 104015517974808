import { Controller } from 'stimulus';

const template = `
<div class="input-group mb-3">
  <input
    type="text"
    name="project_config[kouling_list][]"
    value=""
    class="form-control"
    autocomplete="off"
  />

  <button
    class="btn btn-outline-secondary"
    type="button"
    data-action="click->kouling-form#remove"
  >
    <i class="bi bi-x"></i>
  </button>
</div>
`;

export default class extends Controller {
  add() {
    this.element.insertAdjacentHTML('beforeend', template);
  }

  // eslint-disable-next-line class-methods-use-this
  remove({ target }) {
    target.closest('.input-group').remove();
  }
}
