/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const template = `
<div class="col-md-3" data-time-field>
  <div class="input-group">
    <input type="number" name="project_config[display_times][]" value="60" class="form-control" placeholder=" " min="0">
    <button class="btn btn-outline-secondary" type="button" data-action="click->project-config-time#remove">
      <i class="bi bi-x"></i>
    </button>
  </div>
</div>
`;

export default class extends Controller {
  add({ target }) {
    target.closest('[data-time-field]').insertAdjacentHTML('beforebegin', template);
  }

  remove({ target }) {
    target.closest('[data-time-field]').remove();
  }
}
