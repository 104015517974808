import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
    if (this.choices && this.choices.initialised) return;

    this.choices = new Choices(this.element, {
      removeItemButton: true,
      loadingText: '加载中...',
      noResultsText: '没有数据',
      noChoicesText: '没有可供选择',
      searchPlaceholderValue: '输入搜索',
      itemSelectText: '',
      searchFields: ['label', 'customProperties'],
      searchResultLimit: 10,
      shouldSort: false,
      position: 'bottom',
      addItemText: (value) => `按回车添加 <b>"${value}"</b>`,
    });
  }
}
