import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.chart = echarts.init(this.element, null, {
      renderer: 'svg',
    });
    // Turbolinks 缓存时不渲染
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return;
    }

    this.chart.showLoading({ text: '' });
    fetch(this.urlValue)
      .then((response) => response.json())
      .then((option) => {
        this.chart.setOption(option);
        this.chart.hideLoading();
      });
  }

  disconnect() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  resize() {
    if (this.chart) {
      this.chart.resize();
    }
  }
}
